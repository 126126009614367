<template>
<ul>
    <!-- <a href="/kintone/"><li class="button btn-custom"><i class="fas fa-tachometer-alt"></i>ダッシュボード</li></a> -->
    <a href="/kintone/app1"><li class="button btn-custom"><i class="fas fa-store-alt"></i>店舗別反響・面談表</li></a>
    <a href="/kintone/app2"><li class="button btn-custom"><i class="fas fa-user-tie"></i>単月反響・面談</li></a>
    <a href="/kintone/app3"><li class="button btn-custom"><i class="far fa-calendar-alt"></i>店舗別プロセス表</li></a>
    <a href="/kintone/app4"><li class="button btn-custom"><i class="fas fa-user-tie"></i>営業行動関連</li></a>
    <a href="/kintone/app5"><li class="button btn-custom"><i class="fas fa-store-alt"></i>活動量ランキング</li></a>
    <!-- <a href="/kintone/app6"><li class="button btn-custom"><i class="fas fa-calendar-alt"></i>案内予定表</li></a> -->
    <a href="/kintone/app7"><li class="button btn-custom"><i class="fas fa-user-tie"></i>お客様アンケート</li></a>
    <a href="/kintone/app8"><li class="button btn-custom"><i class="fas fa-user-tie"></i>お客様アンケート回収率</li></a>
</ul>
</template>

<script>
    export default {
    };
</script>
